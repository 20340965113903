import { useQuery } from '@tanstack/react-query';
import ClientFormService from '../services/ClientFormService';
import { ClientFormUser, ClientFormUserDto } from '../models/ClientFormUser';
import useFetchClientUsers from './useFetchClientUsers';

// Custom hook for fetching form users
const useFetchFormUsers = (activityId: string, clientFormUsers: (ClientFormUserDto | ClientFormUser)[], isShared?: boolean) => {
  const { data: clientUsers = [] } = useFetchClientUsers();

  return useQuery({
    queryKey: ['formUsers', activityId, clientUsers, clientFormUsers],
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryFn: async () => {
      if (!clientFormUsers.length) return [];

      let formUsers;
      if ('users' in (clientFormUsers?.[0] ?? {})) {
        formUsers = clientFormUsers.flatMap((x) => (x as ClientFormUserDto).users);
      } else {
        formUsers = (clientFormUsers as ClientFormUser[]).map((x) => clientUsers.find((u) => u.id === x.userId)).filter(Boolean);
      }

      const responseData = isShared ? await ClientFormService.getFormUsers(activityId) : { data: [{ users: formUsers }] };
      const sortedData = responseData.data
        // Taking advantage of spreading `undefined`, not erroring
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .flatMap((x) => x.users.map((u) => ({ ...u, ...(u as any).user })))
        .sort((a, b) => (a.formSectionId === b.formSectionId ? b.role - a.role : a.formSectionId ? 1 : -1));

      return sortedData;
    },
  });
};

export default useFetchFormUsers;
